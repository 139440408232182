@font-face {
    font-family: FSJoey;
    src: url(../assets/fonts/FSJoey-Regular.otf) format("opentype");
}

@font-face {
    font-family: Barlow;
    src: url(../assets/fonts/Barlow-Regular.ttf) format("truetype");
}

@font-face {
    font-family: Montserrat;
    src: url(../assets/fonts/Montserrat-Medium.ttf) format("truetype");
}
@font-face {
    font-family: SegoeUI;
    src: url(../assets/fonts/SegoeUI.ttf) format("truetype");
}

@font-face {
    font-family: Mulish;
    src: url(../assets/fonts/Mulish-Regular.ttf) format("truetype");
    font-weight: normal;
}
@font-face {
    font-family: Mulish;
    src: url(../assets/fonts/Mulish-Bold.ttf) format("truetype");
    font-weight: bold;
}
@font-face {
    font-family: Mulish;
    src: url(../assets/fonts/Mulish-ExtraBold.ttf) format("truetype");
    font-weight: 800;
}

@font-face {
    font-family: 'sura';
    src:  url(../assets/fonts/sura-iconset/sura.eot?52nu86);
    src:  url(../assets/fonts/sura-iconset/sura.eot?52nu86#iefix) format('embedded-opentype'),
        url(../assets/fonts/sura-iconset/sura.ttf?52nu86) format('truetype'),
        url(../assets/fonts/sura-iconset/sura.woff?52nu86) format('woff'),
        url(../assets/fonts/sura-iconset/sura.svg?52nu86#sura) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'cliniweb-icons';
    src:  url(../assets/fonts/cliniweb-icons/Cliniweb.eot?7e9wo1);
    src:  url(../assets/fonts/cliniweb-icons/Cliniweb.eot?7e9wo1#iefix) format('embedded-opentype'),
        url(../assets/fonts/cliniweb-icons/Cliniweb.ttf?7e9wo1) format('truetype'),
        url(../assets/fonts/cliniweb-icons/Cliniweb.woff?7e9wo1) format('woff'),
        url(../assets/fonts/cliniweb-icons/Cliniweb.svg?7e9wo1#Cliniweb) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}