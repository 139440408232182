/* You can add global styles to this file, and also import other style files */
/* @import "~@angular/material/prebuilt-themes/deeppurple-amber.css"; */

@import '~ngx-toastr/toastr';

html {
    overflow: auto !important;
}

html, body {
    height: 100%;
    box-sizing: border-box;
    margin: 0;
    -webkit-overflow-scrolling : touch !important;
    /*overflow: auto !important;*/
}
/* .cdk-global-scrollblock{
    overflow: hidden !important;
}
.cdk-global-scrollblock body{
    position: fixed;
    overflow: hidden;
} */

body:not(.loading-page) .main-spinner{
    display: none;
}

body.loading-page .layout-content,
body.loading-page .cdk-overlay-container{
    display: none;
}

.main-spinner{
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.main-spinner.loading {
    justify-content: flex-start;
}
.main-spinner.loading::before {
    background: url("/assets/images/cliniweb/logo-cliniweb-phr.svg") center no-repeat;
    content: '';
    height: 80px;
    margin-bottom: 36px;
    width: 100%;
}

.main-spinner.loading .logo {
    align-items: center;
    background-image: none;
    border: 1px solid #DCDCDC;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    max-height: 438px;
    max-width: 560px;
    height: 100%;
    width: 100%;
}

.main-spinner.loading .logo::after {
    content: '';
    background: url("/assets/images/loading.svg") no-repeat center / cover;
    animation: spin 1568ms linear infinite;
    height: 120px;
    left: 50%;
    top: 50%;
    width: 120px;
}

.main-spinner .logo{
    content: '';
    background-image: url('assets/images/cliniweb/main-spinner.svg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 150px;
    height: 150px;
    margin-bottom: 56px;
}

.main-spinner .progress{
    width: 200px;
    height: 4px;
    display: block;
    position: relative;
    border-radius: 4px;
    background-color: var(--box-shadow-color);
}

.main-spinner .progress::before{
    position: absolute;
    left: 0;
    height: 4px;
    top: 0;
    background-color: var(--grey8);
    width: 40px;
}

/* .main-spinner{
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #09f;
    animation: spin 1s ease infinite;
} */

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.btn-spinner{
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-spinner svg{
    display: inline-flex;

}
.btn-spinner circle {
  stroke: currentColor;
}
.btn-spinner mat-spinner{
    margin-top: -2px;
    display: inline-flex;
}
.btn-spinner mat-spinner + span{
    padding-left: 8px;
}

.truncate{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

/* Toast */

.toast-top-center {
    top: 10px;
}
.toast-container {
    pointer-events: auto !important;
}
.toast-container .ngx-toastr {
    box-shadow: none;    
}
.toast-title,
.toast-message {
    font-family: Barlow, FSJoey;
}

/* Chat Toast */
.chat-connection-lost-toast {
    background: #8189A9;
    box-shadow: 0px 6px 30px rgb(0 0 0 / 50%);
    border-radius: 8px;
    display: inline-block;
    padding: 20px;
    margin-left: 24px;
    margin-top: 14px;
    position: relative;
}

.chat-connection-lost-toast:hover {
    cursor: pointer;
}

.chat-connection-lost-toast::after {
    font-family: 'sura' !important;    
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    content: "\e908";
    color: white;
    font-size: 22px;    
    vertical-align: top;
    position: absolute;
    right: 10px;
    top: 10px;
}

.chat-connection-lost-toast .toast-title::before {
    font-family: 'sura' !important;    
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    content: "\e930";
    color: white;
    font-size: 22px;
    padding-right: 5px;
    vertical-align: bottom;
}

.chat-connection-lost-toast .toast-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
}

.chat-connection-lost-toast .toast-message {
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
}



.skeleton-item {
    background: #fff;
    border-radius: 2px;
    position: relative;
    overflow: hidden;
}
.skeleton-item.background-grey {
    background-color: var(--grayscale12) !important;
    display: block;
    margin-bottom: 4px;
}
.skeleton-item.background-grey,
.skeleton-item.background-grey * {
    color: var(--grayscale12) !important;
}
    .skeleton-item::before {
        content: '';
        display: block;
        position: absolute;
        left: -150px;
        top: 0;
        height: 100%;
        width: 150px;
        background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
        animation: load-skeleton 1.5s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    }

    .skeleton-item.circle {
        border-radius: 50%;
    }
@keyframes load-skeleton {
    from {
        left: -150px;
    }

    to {
        left: 100%;
    }
}
.form-field-outline-simple {
    padding-bottom: 12px !important;
}
.form-field-outline-simple .mat-form-field-wrapper{
    margin: 0 !important;
    padding-bottom: 0px !important;
}

.form-field-outline-simple .mat-form-field-flex{
    padding: 0 16px !important;
    margin-top: 0 !important;
    align-items: center;
}

.form-field-outline-simple .mat-form-field-outline{
    top: 0 !important;
}
.form-field-outline-simple .mat-form-field-infix{
    border: unset !important;
    padding: 10px 0 11px 0 !important;
}

.form-field-outline-simple .mat-form-field-infix input{
    height: 20px;
    margin-top: 0;
    font-family: var(--font-primary);
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
}

.form-field-outline-simple .mat-form-field-suffix{
    top: 0 !important;
}

.form-field-outline-simple .mat-form-field-outline-start{
    border-radius: 6px 0 0 6px !important;
}

.form-field-outline-simple .mat-form-field-outline-end{
    border-radius: 0 6px 6px 0 !important;
}
.form-field-outline-simple.mat-form-field-invalid .mat-form-field-subscript-wrapper{
    padding: 0 !important;
    margin-top: 8px !important;
    margin-bottom: 12px;
    position: relative !important;
}
.form-field-outline-simple .mat-error{
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: 4px;
}
.form-field-outline-simple .mat-error .mat-icon{
    font-size: 17px;
    width: 17px;
    height: 17px;
    line-height: 17px;
}



/* ngx-mat-intl-tel-input Styles */

.phone-number-container .country-selector {
    top: 3px !important;
    left: -10px !important;
    opacity: 1 !important;
    height: 49px !important;
    padding: 0px !important;
    width: 60px !important;
}

.phone-number-container .country-selector-code {
    display: none !important;
}

.phone-number-container .country-selector > span.mat-button-wrapper {
    margin-left: -7px !important;
}

.phone-number-container .mat-form-field-infix {
    padding: 0px !important;
    border: none !important;
    height: 55px;
}

.phone-number-container .ngx-mat-tel-input-container {
    padding-top: 5px;
}

.phone-number-container .ngx-mat-tel-input-container input {
    padding-left: 66px !important;
    height: 49px;
}

.phone-number-container .mat-form-field-label {
    margin-top: 7px !important;
}

/* /ngx-mat-intl-tel-input Styles */

/* item validation */

.validation-wrapper > span{ 
    font-family: var(--font-primary);
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    color: #757575;
    display: block;
    margin-bottom: 8px;
}

.validation-wrapper .item-validation{
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 4px;
}

.validation-wrapper .item-validation > span{
    font-family: var(--font-primary);
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    color: #757575;
}
.validation-wrapper .item-validation > .mat-icon{
    font-size: 17px;
    height: 17px;
    width: 17px;
    line-height: 17px;
    color: #BEBEBE;
}

.validation-wrapper .item-validation.success > span,
.validation-wrapper .item-validation.success > .mat-icon{
    color: #409954;
}

.validation-wrapper .item-validation.error > span,
.validation-wrapper .item-validation.error > .mat-icon{
    color: #FF4C4C ;
}

.validation-wrapper .item-validation.error > .mat-icon:before{
    content: '\e99d';
}

.capitalize-text{
    text-transform: capitalize;
}

/* route dialog */

.route-dialog-modal{
    max-width: 100vw !important;
    max-height: 100vh;
    height: 100%;
    width: 100% !important;    
}
.route-dialog-modal .mat-dialog-container{
    padding: 0!important;
    border-radius:0!important;
}

.route-dialog-modal.public-profile-dialog{
    margin-left: calc(100% - 1280px);
    padding-left: 0px;
}

.route-dialog-modal.reschedule-appointment-route-dialog {
    margin-left: 150px;
}

.public-profile-animation-in{
    animation: slideInRight;
    -webkit-animation-duration: 300ms;
    animation-duration: 300ms;
    -webkit-animation-duration: 300ms;
    animation-duration: 300ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.public-profile-animation-out{
    animation: slideOutRight;
    -webkit-animation-duration: 300ms;
    animation-duration: 300ms;
    -webkit-animation-duration: 300ms;
    animation-duration: 300ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.appointment-dialog{
    padding: 0;
}

.cancel-appointment-route-dialog {
    padding: 0;
}

.cancel-appointment-route-dialog .mat-dialog-container {
    background: transparent !important;
}

.appointment-animation-in{
    animation: slideInDown;
    -webkit-animation-duration: 300ms;
    animation-duration: 300ms;
    -webkit-animation-duration: 300ms;
    animation-duration: 300ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.appointment-animation-out{
    animation: slideOutUp;
    -webkit-animation-duration: 300ms;
    animation-duration: 300ms;
    -webkit-animation-duration: 300ms;
    animation-duration: 300ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.map-phr-animation-in{
    animation: slideInUp;
    -webkit-animation-duration: 300ms;
    animation-duration: 300ms;
    -webkit-animation-duration: 300ms;
    animation-duration: 300ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.map-phr-animation-out{
    animation: slideOutDown;
    -webkit-animation-duration: 300ms;
    animation-duration: 300ms;
    -webkit-animation-duration: 300ms;
    animation-duration: 300ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.next-appointment-animation-in {
    animation: slideInLeft;
    -webkit-animation-duration: 300ms;
    animation-duration: 300ms;
    -webkit-animation-duration: 300ms;
    animation-duration: 300ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.next-appointment-animation-out{
    animation: slideOutLeft;
    -webkit-animation-duration: 300ms;
    animation-duration: 300ms;
    -webkit-animation-duration: 300ms;
    animation-duration: 300ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@media only screen and (max-width: 1600px){
    .route-dialog-modal.public-profile-dialog{
        margin-left: auto;
        width: 80vw !important;
    }
}

@media only screen and (max-width: 800px) {
    
    html.overflow-disabled{
        overflow: hidden !important;
        position: fixed;
        width: 100%;
        left: 0px;
        top: 0px;
        max-height: 100%;
    }
    html.overflow-disabled body{
        overflow: hidden !important;
        position: fixed;
        width: 100%;
        left: 0px;
        top: 0px;
        max-height: 100%;
        height: 100%;
        padding: 0 !important;
    }

    .route-dialog-modal.public-profile-dialog{
        margin-left: 0;
        padding-top: 0;
        width: 100vw !important;
    }

    .route-dialog-modal.public-profile-dialog .mat-dialog-container{
        border-radius: 0 !important;
    }

    .route-dialog-modal.reschedule-appointment-route-dialog {
        margin-left: 0px;
        padding-top: 0px;
    }

    .route-dialog-modal.reschedule-appointment-route-dialog .mat-dialog-container {
        border-radius: 16px 16px 0 0 !important;
    }
    .public-profile-animation-in{
        animation: slideInUp;
        -webkit-animation-duration: 300ms;
        animation-duration: 300ms;
        -webkit-animation-duration: 300ms;
        animation-duration: 300ms;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
    .public-profile-animation-out{
        animation: slideOutDown;
        -webkit-animation-duration: 300ms;
        animation-duration: 300ms;
        -webkit-animation-duration: 300ms;
        animation-duration: 300ms;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }

    .appointment-info-animation-in{
        animation: slideInUp;
        -webkit-animation-duration: 300ms;
        animation-duration: 300ms;
        -webkit-animation-duration: 300ms;
        animation-duration: 300ms;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
    .appointment-info-animation-out{
        animation: slideOutDown;
        -webkit-animation-duration: 300ms;
        animation-duration: 300ms;
        -webkit-animation-duration: 300ms;
        animation-duration: 300ms;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }

    .appointment-animation-in {
        animation: slideInRight;
        -webkit-animation-duration: 300ms;
        animation-duration: 300ms;
        -webkit-animation-duration: 300ms;
        animation-duration: 300ms;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
    .appointment-animation-out {
        animation: slideOutRight;
        -webkit-animation-duration: 300ms;
        animation-duration: 300ms;
        -webkit-animation-duration: 300ms;
        animation-duration: 300ms;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }

    .confirm-logout-animation-in{
        animation: slideInUp;
        -webkit-animation-duration: 300ms;
        animation-duration: 300ms;
        -webkit-animation-duration: 300ms;
        animation-duration: 300ms;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
    .confirm-logout-animation-out{
        animation: slideOutDown;
        -webkit-animation-duration: 300ms;
        animation-duration: 300ms;
        -webkit-animation-duration: 300ms;
        animation-duration: 300ms;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }

    .map-phr-animation-in {
        animation: slideInRight;
        -webkit-animation-duration: 300ms;
        animation-duration: 300ms;
        -webkit-animation-duration: 300ms;
        animation-duration: 300ms;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
    .map-appointment-animation-out {
        animation: slideOutRight;
        -webkit-animation-duration: 300ms;
        animation-duration: 300ms;
        -webkit-animation-duration: 300ms;
        animation-duration: 300ms;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
}

/* animations */

.slideInUp{
    animation: slideInUp;
    -webkit-animation-duration: 300ms;
    animation-duration: 300ms;
    -webkit-animation-duration: 300ms;
    animation-duration: 300ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.slideInDown{
    animation: slideInDown;
    -webkit-animation-duration: 300ms;
    animation-duration: 300ms;
    -webkit-animation-duration: 300ms;
    animation-duration: 300ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.slideOutDown{
    animation: slideOutDown;
    -webkit-animation-duration: 300ms;
    animation-duration: 300ms;
    -webkit-animation-duration: 300ms;
    animation-duration: 300ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.slideOutUp{
    animation: slideOutUp;
    -webkit-animation-duration: 300ms;
    animation-duration: 300ms;
    -webkit-animation-duration: 300ms;
    animation-duration: 300ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.slideInRight{
    animation: slideInRight;
    -webkit-animation-duration: 300ms;
    animation-duration: 300ms;
    -webkit-animation-duration: 300ms;
    animation-duration: 300ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.slideOutRight{
    animation: slideOutRight;
    -webkit-animation-duration: 300ms;
    animation-duration: 300ms;
    -webkit-animation-duration: 300ms;
    animation-duration: 300ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.slideInLeft {
    animation: slideInLeft;
    -webkit-animation-duration: 300ms;
    animation-duration: 300ms;
    -webkit-animation-duration: 300ms;
    animation-duration: 300ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.slideOutLeft {
    animation: slideOutLeft;
    -webkit-animation-duration: 300ms;
    animation-duration: 300ms;
    -webkit-animation-duration: 300ms;
    animation-duration: 300ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes slideInUp {
    0% {
        -webkit-transform: translate3d(0,100%,0);
        transform: translate3d(0,100%,0);
        visibility: visible
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
}

@keyframes slideInUp {
    0% {
        -webkit-transform: translate3d(0,100%,0);
        transform: translate3d(0,100%,0);
        visibility: visible
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
}

@-webkit-keyframes slideInDown {
    0% {
        -webkit-transform: translate3d(0,-100%,0);
        transform: translate3d(0,-100%,0);
        visibility: visible
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
}

@keyframes slideInDown {
    0% {
        -webkit-transform: translate3d(0,-100%,0);
        transform: translate3d(0,-100%,0);
        visibility: visible
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
}

@-webkit-keyframes slideOutDown {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(0,100%,0);
        transform: translate3d(0,100%,0)
    }
}

@keyframes slideOutDown {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(0,100%,0);
        transform: translate3d(0,100%,0)
    }
}

@-webkit-keyframes slideOutUp {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(0,-100%,0);
        transform: translate3d(0,-100%,0)
    }
}

@keyframes slideOutUp {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(0,-100%,0);
        transform: translate3d(0,-100%,0)
    }
}

@-webkit-keyframes slideInRight {
    0% {
        -webkit-transform: translate3d(100%,0,0);
        transform: translate3d(100%,0,0);
        visibility: visible
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
}

@keyframes slideInRight {
    0% {
        -webkit-transform: translate3d(100%,0,0);
        transform: translate3d(100%,0,0);
        visibility: visible
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
}

@-webkit-keyframes slideOutRight {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(100%,0,0);
        transform: translate3d(100%,0,0)
    }
}

@keyframes slideOutRight {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(100%,0,0);
        transform: translate3d(100%,0,0)
    }
}

@-webkit-keyframes slideInLeft {
    0% { 
        visibility: visible; 
        -webkit-transform: translateX(-100%); 
    }    
    100% { 
        -webkit-transform: translateX(0%); 
    }
}

@keyframes slideInLeft {    
    0% { 
        visibility: visible; 
        transform: translateX(-100%);
    }    
    100% {
        transform: translateX(0%); 
    }    
}

@-webkit-keyframes slideOutLeft {
    0% { 
        -webkit-transform: translateX(0%); 
    }
    100% { 
        visibility: hidden; 
        -webkit-transform: translateX(-100%); 
    }
}

@keyframes slideOutLeft {
    0% { 
        transform: translateX(0%); 
    }
    100% { 
        visibility: hidden;
        transform: translateX(-100%); 
    }
}